<template>
  <div class="full-height">
      <Header :speakerhash="this.$route.params.hash" :showDate="false" :alwaysShow="true" :title="$t('schedule.confirm_speaker')" ></Header>
      
      <div v-if="returned && not_expired">
      <div id="speaker-description-back">
        <div class="fit-width" id="speaker-description">
            <span class="form-box-name speaker-form-name">{{$t('schedule.member_name')}}</span>
            <b>{{speaker.name}}</b>
            <span class="form-box-name speaker-form-name">{{$t('schedule.date_and_time')}}</span>
            <b>{{speaker.date_time}}</b>
        </div>
        </div>
        <div class="main-colour center-text">
            <div>{{$t('schedule.thank_you')}}</div>
            <br/>
            <div v-html="speaker.status==0? $t('schedule.speaker_confirmed') : $t('schedule.speaker_declined')"></div>
        </div>
    </div>
    <div v-if="returned && !not_expired" id="expired-box" class="main-colour center-text">
        <div>{{$t('schedule.sorry_text')}}</div>
        <br/>
        <div>{{$t('schedule.link_expired')}}</div>
    </div>

      <div class="footer white-text center">
        © 2024 BNI Global, LLC <br/>
        Use of this site is subject to our <a v-on:click="goTerms()" class="clickable footer-link" target="_blank"><u>Terms and Conditions</u></a> and <a class="clickable footer-link" v-on:click="goPrivacy()" target="_blank"><u>Privacy Policy</u></a>.<br/>
        Powered by <i>Meeting HUB</i>
    </div>
  </div>
</template>
<style scoped>
#expired-box{
  margin-top: 200px;
}
.speaker-form-name{
  margin: 0px 5px;
}
#speaker-description-back{
  background-color: #eeeeee;padding: 10px;max-width: 600px;margin: 50px auto;
}
#logo-box{
     margin: auto;margin-bottom:25px; max-width: 600px;
    background-image: url("../assets/logo-MeetingHubwSlogan2020.png");
    background-repeat: no-repeat;
    background-size: auto 150px;
    background-position: center;
    width:600px;
    background-color: #FFFFFF;
    height: 150px;
}
.center{
        text-align: center;
    }
    .underline{
        text-decoration: underline;
    }
    .white-text{
        color: #fff !important;
    }
#speaker-description{
  display: grid;grid-template-columns: max-content max-content;margin: auto;
}
</style>
<script>
export default {
  name: "ConfirmSpeaker",
  data() {
        return {
            alertText: "",
            alertVariant: "success",
            hash : "",
            returned: false,
            not_expired: false,
            speaker: {name: '', date_time: ''}
        }
  },
  methods: {
    goTerms(){
          if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/terms-of-service", "_blank");
          }else{
            window.open("https://bnitos.com", "_blank");
          }
       },
       goPrivacy(){
         if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/privacy-policy", "_blank");
         }else{
           window.open("https://bnitos.com/privacy", "_blank");
         }
       },
    confirm(){
    if(this.$route.params.hash){
        this.hash = this.$route.params.hash;
      }
      this.$http.post("/api/organization/speaker/confirm",{hash: this.hash, confirmed: this.$route.params.confirmed}).then((response) => {
          if(response.data.success){
             this.speaker = response.data.speaker;
             this.not_expired = this.speaker.not_expired;
          }else{
              this.not_expired = false;
          }
          this.returned = true;
      }).catch((e) => { 
          this.returned = true;
       console.log(e);
      })
    },
  },
  created() {
    this.confirm();
  }
}
</script>
