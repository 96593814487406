<template>
<div>
    <header >
        <nav class="z-depth-1">
            <div class="nav-wrapper">
                <a v-on:click="goHome()" class="brand-logo left clickable"><img id="privacy-logo" src="../assets/logo-MeetingHubwSlogan2020.png"></a>
            </div>
        </nav>
    </header>
    <div class="scaleable-wrapper" id="scaleable-wrapper" >
        <div id="main-template" class="z-depth-1" >
            <div>
                <div id="privacy-text">
						
	<div class="text-size3 center">Meeting HUB Privacy Policy</div>
	<p>Version Date: September 24, 2021</p>

<p>OUR COMMITMENT TO PRIVACY</p>

<p>Meeting HUB INC. is committed to your privacy and protecting it through our compliance with this privacy policy (“<strong>Privacy Policy</strong>”). Meeting HUB has developed this Privacy Policy in accordance with the the Personal Information Protection and Electronic Documents Act (“<strong>PIPEDA</strong> and other relevant privacy laws.  This Privacy Policy explains how Meeting HUB (“<strong>Meeting HUB</strong>”, “<strong>us</strong>”, “<strong>our</strong>”, and “<strong>we</strong>”) collects and uses your information and applies to all individuals who use this website and the software and applications made available on our website (collectively the “<strong>Site</strong>”) and the services provided through the Site (the “Services”).</p>
<p>Please read this Privacy Policy carefully and review it periodically for the latest information about our privacy practices. <strong>By visiting and/or using the Site, you expressly consent to our collection, use and disclosure of your Personal Information in accordance with this Privacy Policy. If you do not agree with the practices described in this Privacy Policy, please do not provide us with your Personal Information or interact with the Site.</strong></p>
<p>This Privacy Policy is incorporated into and subject to our Terms and Conditions of Use available at <a href="https://cc.meetinghub.biz//#/terms">https://cc.meetinghub.biz//#/terms</a> (“<strong>Terms</strong>”).  Undefined capitalized terms used in this Privacy Policy have the meanings given to them in our Terms.</p>

<p><strong><span class="underline">I. SCOPE</span></strong></p>

<p>This Privacy Policy applies to all Personal Information collected, used or disclosed by Meeting HUB from users of our Site (“<strong>Users</strong>”), including you. “<strong>Personal Information</strong>” is any information in any form that identifies or can identify an individual or could be combined other information to identify an individual.</p>

<p>This Privacy Policy also applies to information that is about you but individually does not identify you, as described below.</p>

<p><strong><span class="underline">II. ACCOUNTABILITY</span></strong></p>

<p>Meeting HUB is responsible for the Personal Information under its possession and control.  Meeting HUB shall make known, upon request, the title of the person or persons designated to oversee Meeting HUB’s compliance with this Privacy Policy.</p>
<p>Meeting HUB is responsible for Personal Information in its possession or custody, including information that has been transferred to a third party for processing (a “<strong>Service Provider</strong>”). Meeting HUB shall use contractual or other means to provide a comparable level of protection while the information is being processed by a Service Provider.</p>
<p>Meeting HUB shall implement policies and practices to give effect to these principles, including:</p>
<ul>
	<li>•	implementing procedures to protect Personal Information;</li>
	<li>•	establishing procedures to receive and respond to complaints and inquiries;</li>
	<li>•	training staff and communicating to staff information about the organization’s policies and practices; and</li>
	<li>•	developing information to explain the organization’s policies and procedures.</li>
</ul>

<p><strong><span class="underline">III. THE INFORMATION WE COLLECT</span></strong></p>

<p><span class="italic">Personal Information</span></p>
<p>Personal Information that Meeting HUB collects includes, but is not limited to:</p>
<ul>
	<li>•	contact information held by your organization, such as name, address, email address, telephone number and roles and positions that you may hold in your organization;</li>
	<li>•	attendance information with respect to your organization’s meetings;</li>
	<li>•	Personal Information that may be included in any presentations you present at your organization’s meetings;</li>
	<li>•	performance data that your organization may collect;</li>
	<li>•	request for additional information about our services or company, or asking us to contact you;</li>
	<li>•	any other Personal Information that you may provide when you use the Services, or when you contact Meeting HUB with questions and/or inquiries;</li>
	<li>•	technical support records; and</li>
	<li>•	any information that we collect from you that is intended to improve and personalize our services.</li>
</ul>
<p><span class="italic">Non-Personal Information</span></p>

<p>Meeting HUB collects non-Personal Information (“<strong>NPI</strong>”) about Users when they interact with the Site or use our services. For instance, NPI may include, but is not limited to, browser name, type of computer or device, and technical information about a User’s means of connecting to the Site, such as operating system, Internet service provider utilized, and mobile network information. See the section below entitled “<span class="italic">How does Meeting HUB collect, use and share NPI?</span>” for more information.</p>

<p>Please note that Personal Information does not include information that is aggregated or anonymized in such a manner that it cannot be connected to you or any other User. Such information by itself or together cannot be tracked to a specific individual’s Personal Information.</p>


<p><strong><span class="underline">IV. HOW DOES MEETING HUB USE PERSONAL INFORMATION</span></strong></p>

<p>Meeting HUB collects Personal Information for the following purposes:</p>

<ul>
	<li>•	to provide Services to you and to support your organization in running and administering its meetings;</li>
	<li>•	to establish and maintain responsible commercial relations with you;</li>
	<li>•	to communicate with you in order to provide our Services;</li>
	<li>•	to communicate with you about your requests and inquiries;</li>
	<li>•	to recommend or to offer you other products and services based on your inferred interests;</li>
	<li>•	to communicate with you regarding service issues;</li>
	<li>•	to protect the security or integrity of our website and our business;</li>
	<li>•	to enforce our Terms;</li>
	<li>•	to meet legal and regulatory requirements;</li>
	<li>•	to improve our Site and Services; and</li>
	<li>•	for any other reasonable purposes for which you may have provided your express consent or in which your consent can be reasonably implied.</li>
</ul>

<p><strong><span class="underline">V.  HOW DOES MEETING HUB COLLECT, USE AND SHARE NPI?</span></strong></p>  

<p>Meeting HUB uses NPI in an ongoing effort to better understand and serve our customers and to improve the content and functionality of our Site and Services.</p>

<p>In addition, Meeting HUB  may use Personal Information, on an aggregated de-identified basis, for research purposes to improve our Site or services.  This aggregated de-identified information does not identify any individual and therefore is considered and treated as NPI under this Privacy Policy.  For instance, Meeting HUB uses NPI to monitor traffic to the Site and to collect statistical data regarding use of the Site.</p>

<p>No NPI by itself, or together with aggregated Personal Information, can be tracked to a specific individual's Personal Information.</p>

<p><strong><span class="underline">VI. CONSENT</span></strong></p>

<p>Meeting HUB respects your privacy and, unless otherwise required by law, Meeting HUB will not collect, use or disclose your Personal Information without your prior consent. Your consent may be expressed or implied. You may expressly give your consent in writing, verbally or through any electronic means.  In certain circumstances, your consent may be implied by your actions.  For example, providing Meeting HUB with Personal Information to register is implied consent to use such information to provide you with the services.</p>

<p>Where appropriate, Meeting HUB will generally seek consent for the use or disclosure of the information at the time of collection. In certain circumstances, consent with respect to use or disclosure may be sought after the information has been collected but before use (for example, when Meeting HUB wants to use information for a purpose other than those identified above).  In obtaining consent, Meeting HUB will use reasonable efforts to ensure that a User is advised of the identified purposes for which Personal Information collected will be used or disclosed.</p>

<p>The form of consent sought by Meeting HUB may vary, depending upon the circumstances and type of information disclosed. In determining the appropriate form of consent, Meeting HUB shall take into account the sensitivity of the Personal Information and the reasonable expectations of Meeting HUB’ Users.  Meeting HUB will seek express consent when the information is likely to be considered sensitive. Implied consent will generally be appropriate where the information is less sensitive.</p> 

<p>You may withdraw consent at any time, subject to legal or contractual restrictions and reasonable notice. In order to withdraw consent, you must provide notice to Meeting HUB in writing.</p>   

<p><strong><span class="underline">VII.  LIMITING USE, DISCLOSURE AND RETENTION</span></strong></p>

<p>As noted above, Meeting HUB does not disclose your Personal Information to third parties, except as provided for in this Privacy Policy.  In conjunction with using the Services, your Personal Information will be made available to other members of your organization.  Meeting HUB may transfer your Personal Information to Service Providers who are engaged by us in providing and developing products and servies, storing data (including Personal Information), and providing you with the Services.  For example, Meeting HUB uses Stripe for payment processing services.</p>  

<p>Only Meeting HUB’s employees, agents, contractors, Service Providers (and their employees and contractors) with a business need to know, or whose duties reasonably so require, are granted access to Personal Information about you.   All such employees and contractors will be required as a condition of employment to contractually respect the confidentiality of Meeting HUB Users’ Personal Information.</p>

<p>Meeting HUB will retain Personal Information for only as long as required to fulfill the identified purposes or as required by law. Personal Information that is no longer required to fulfill the identified purposes will be destroyed, erased or made anonymous according to guidelines and procedures established by Meeting HUB.</p>

<p>Meeting HUB will disclose Personal Information without your knowledge or consent if Meeting HUB receives an order, subpoena, warrant or other legal requirement issued by a court, tribunal, regulator or other person with jurisdiction to compel disclosure of your Personal Information. If Meeting HUB receives a written request from a police officer or other law enforcement agency with authority to request access to your Personal Information in the course of an actual or potential investigation into a breach of a law, Meeting HUB’s policy is to provide the requested information.</p>

<p><strong><span class="underline">VIII. COOKIES &amp; OPT-OUT</span></strong></p>

<p>In order to improve the Site, Meeting HUB may use small files commonly known as “cookies”. A cookie is a small amount of data, which often includes a unique identifier that is sent to your computer or mobile phone (your “device”) from the Site and is stored on your device’s browser or hard drive.  Cookies are used in analyzing trends, administering the site, tracking users’ movements around the site and to gather demographic information about our user base as a whole. Meeting HUB may generate reports based on the use of these technologies an aggregated basis.</p>

<p>The cookies Meeting HUB  uses on the Site won't collect personally identifiable information about you and we won't disclose information stored in cookies that we place on your device to third parties.  Meeting HUB uses cookies as follows:</p>
 
<ul>
	<li>•	To provide an enhanced experience for Users;</li>
	<li>•	To identify registered Users;</li>
	<li>•	To monitor  and anlalyze the performance, operation and effectiveness of the Site; and</li>
	<li>•	To ensure the security of the Site.</li>
</ul>

<p>You are able to disable cookies directly in your website browser. Visit www.AboutCookies.org for information.  If you reject cookies, you may still use the Site, but your ability to use some features or areas of our site may be limited.</p>

<p><strong><span class="underline">IX. HOW CAN I ACCESS MY PERSONAL INFORMATION</span></strong></p>

<p>Upon request, Meeting HUB will provide you information regarding the existence, use and disclosure of your Personal Information and you will be given access to that information.  Meeting HUB will respond to an application for individual access to Personal Information within a reasonable time and at minimal or no cost to the individual. An individual may challenge the accuracy and completeness of the information and have it amended as appropriate.</p>

<p><strong>NOTE</strong>: In certain circumstances, Meeting HUB may not be able to provide access to all of your Personal Information that it holds.  Exceptions may include information that is prohibitively costly to provide, information that contains references to other individuals, information that cannot be disclosed for legal, security or commercial proprietary reasons, or information that is subject to solicitor-client or litigation privilege. Meeting HUB will provide the reasons for denying access upon request.</p>

<p><strong><span class="underline">X. SAFEGUARDS</span></strong></p>

<p>We have implemented administrative, technical and physical measures to safeguard your Personal Information against accidental or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access of Personal Information. This includes:</p>

<ul>
	<li>•	utilizing access controls and firewall controls of computer servers;</li>
	<li>•	limiting access of employees to, and the use of, Personal Information through the use of passwords and graduated levels of clearance;</li>   
	<li>•	the use of pseudonymization and encryption of Personal Information, where appropriate; </li>
	<li>•	measures to ensure the ongoing confidentiality, integrity, availability and resilience of its systems and services; and</li>
	<li>•	regularly monitoring and evaluating the effectiveness of technical and organisational measures for ensuring the security of the processing of Personal Information.</li>
</ul>

<p>Although we take precautions against possible breaches of our security systems, no company can fully eliminate the risks of unauthorized access to your Personal Information and no website is completely secure. We cannot guarantee that unauthorized access, hacking, data loss or breaches of our security systems will never occur. Accordingly, you should not transmit Personal Information to us using our Site if you consider that information to be sensitive.</p> 
<p><strong><span class="underline">XI. INQUIRIES; CHALLENGING COMPLIANCE</span></strong></p>

<p>An individual can make inquiries and/or address a challenge concerning compliance with this Privacy Policy to Meeting HUB’s Privacy Officer.</p> 

<p>Meeting HUB will maintain procedures for addressing and responding to all inquiries or complaints about Meeting HUB's handling of Personal Information. Meeting HUB shall investigate all complaints. All inquiries or complaints involving Meeting HUB’ handling of Personal Information or compliance with this Privacy Policy or with PIPEDA shall be directed to Meeting HUB's Privacy Officer.</p> 

<p>Meeting HUB will respond to all such inquiries or complaints within 14 business days of receipt.  Meeting HUB will make reasonable efforts to resolve all such complaints within 30 days of receipt of the initial complaint. If Meeting HUB finds a complaint to be justified, it will take appropriate measures, including, if necessary, amending its policies and procedures.</p> 

<p>For more information, please contact Meeting HUB’s Privacy Office as follows:</p> 

<p>E-mail: <a href="mailto:info@meetinghub.biz">info@meetinghub.biz</a></p>

<p>Mail:<br>
Meeting HUB INC.<br>
Privacy Officer<br>
83 Holyrood Ave<br>
Oakville, ON<br>
L6K 2V4</p>

<p><strong><span class="underline">XII. STORAGE OUTSIDE OF CANADA</span></strong></p>

<p>Your Personal Information may be used or stored by us or our service providers outside of Canada. We require that our service providers safeguard your Personal Information. However, if your Personal Information is used or stored outside of Canada, it will also be subject to the laws of the country in which it is used or stored.</p>  

<p><strong><span class="underline">XIII. CHILDREN AND MINORS</span></strong></p>

<p>The Site is not directed to children under the age of 13 years.  Meeting HUB will not knowingly collect Personal Information about a child under the age of 13.  Meeting HUB does not allow minors (persons who are under the age of majority in their place of residence) to use the Site or Services.</p>

<p><strong><span class="underline">XIV. GENERAL</span></strong></p>

<p>Meeting HUB has the right to modify and amend this Privacy Policy from time to time in its sole discretion without prior notice to clarify our practices and to reflect new or different privacy practices, such as when we add new services, functionality or features to the Site. Any such amendment(s) will be posted on the Site and will be effective as of the date of posting.  If we make any material changes we will notify you by e-mail (sent to the e-mail address specified in your account, if any). You can determine when this Privacy Policy was last revised by referring to the version date listed above.</p>



                </div>
                <br/>
                <div class="footer white-text center">
                    © 2024 BNI Global, LLC <br/>
        Use of this site is subject to our <a v-on:click="goTerms()" class="clickable footer-link" target="_blank"><u>Terms and Conditions</u></a> and <a class="clickable footer-link" v-on:click="goPrivacy()" target="_blank"><u>Privacy Policy</u></a>.<br/>
        Powered by <i>Meeting HUB</i>
                </div>
            </div>
        </div>
    </div>
</div>  
</template>
<style scoped>
#privacy-logo{
    height:76px;
}
#privacy-text{
    padding:40px;padding-bottom:70px;background-color:#fafafa;
}
.left{
    float: left !important;
}
.brand-logo{
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;
    top: 28px;
    left: 26px;
}
.nav-wrapper{
    position: relative;
    background-color: #FFF!important;
    font-size: 16px;
    height: 117px!important;
    width: 100%;
    margin: auto;
    padding: 10px 125px;
}
html{
        line-height: 1.5;
        font-family: Metropolis-Regular;
    font-weight: normal;
    color: rgba(0,0,0,0.87);
    -webkit-text-size-adjust: 100%;
}
header{
    max-width: 1280px;
    margin: auto;
}
nav{
    position: relative;
    background-color: #FFF!important;
    font-size: 16px;
    height: 117px!important;
    width: 100%;
    margin: auto;
}
@media (min-width: 0){
    #main-template {
        font-size: 14px;
    }
}
@media (min-width: 992px){
    #main-template {
        font-size: 14.5px;
    }
}
@media (min-width: 1200px){
    #main-template {
        font-size: 15px;
    }
}
p{
        display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}
.scaleable-wrapper{
    overflow: hidden;
}
#main-template{
        width: 1280px;
    position: relative;
    margin: auto;
    max-width: 100%;
}
.z-depth-1{
    -webkit-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.text-size3{
    font-size: 20px;
    line-height: 1.1em;
}
    .center{
        text-align: center;
    }
    .underline{
        text-decoration: underline;
    }
    .white-text{
        color: #fff !important;
    }
</style>
<script>
import router from "../router"
    export default {
    name: "Terms",
  data() {
    return {
     
    };
  },
   methods: {
       goHome(){
           router.push("/login");
       },
       goTerms(){
           router.push("/terms");
       },
       goPrivacy(){
           router.push("/privacy");
       }
  },
   mounted() {
    
  },
}
</script>