<template>  
  <div id="centre-div-parent" :class="{'rel-grid-container' : browser_good}">
    <div ></div>
    <div v-if="!browser_good">
      <div id="logo-box-wrapper">
        <div id="logo-box">
        </div>
      </div>
      <span id="loginText">{{$t('login.ie_message_1')}}</span>
      <span id="loginText">{{$t('login.ie_message_2')}}</span>
    </div>
    <div v-if="browser_good">
      <div id="logo-box-wrapper">
        <div id="logo-box" v-if="!isCorporateConnections">
        </div>
      </div>
      <div id="cc-box" v-if="isCorporateConnections">

      </div>
      <div id="welcome-text" v-if="!isCorporateConnections">
        {{$t('login.bniplus_welcome')}}
      </div>
      <div class="login-form-wrapper">
      <div class="login-form">
        <span id="permission-text" class="centre-text log-title">{{$t('login.permission_text')}}</span><br />
        <span id="sub-text" class="centre-text" v-if="loginStep<2" >{{$t('login.login_sub_text')}}</span><br />
        <transition-group :name="slide_transition">
          <div v-if="loginStep==0" key="login1" class="login-step-box">
            <form  v-on:submit.prevent="submitEmail" class="fit-width login-form-box">
              <b-form-input :placeholder="$t('login.username_text')" class="login-field" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" v-model="username"  required></b-form-input>
              <b-form-input id="invis-password" :placeholder="$t('login.password_text')" class="login-field"  type="password" v-model="password"></b-form-input>
              <b-button id="first-next-button" class="action-button login-next-button" type="submit" variant="clear-blue"><div class="next-button next-icon"></div></b-button>
            </form>
          </div>
          <div v-if="loginStep==1" key="login2" class="login-step-box">
            <form v-on:submit.prevent="login" class="fit-width login-form-box">
              <b-form-input :placeholder="$t('login.username_text')" class="login-field" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" v-model="username"  required></b-form-input>
              <b-form-input id="password-field" :placeholder="$t('login.password_text')" class="login-field"  type="password" v-model="password"  required></b-form-input>
              <b-button class="action-button  login-next-button" type="submit" variant="clear-blue"><div class="next-button next-icon"></div></b-button>
            </form>
            <b-btn id="forgot-password" v-on:click="reset_name=username;showResetSuccess=false;showPassReset = true;" variant="link">{{$t('login.forgot_password')}}</b-btn>
          </div>
        <div v-if="loginStep>=2" key="login3" class="valign-grid login-step-box">
          <div></div>
          <div class="center-text">
            <p v-if="loginStep==4" v-html="$t('login.set_pass_sent')"></p>
            <p v-if="loginStep==2 || loginStep == 3" v-html="$t('login.email_sent')"></p><br v-if="loginStep == 3"/>
            <p v-if="loginStep == 3" v-html="$t('login.further_access')"></p>
          </div>
          <div></div>
        </div>
        </transition-group>
      </div>
    </div>
      <b-alert id="login-alert" class="fit-width" variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false"> {{alertText}} </b-alert>
      
    </div>
    <div></div>
    <div id="login-footer" class="footer white-text center">
        © 2024 BNI Global, LLC <br/>
        Use of this site is subject to our <a v-on:click="goTerms()" class="clickable footer-link" target="_blank"><u>Terms and Conditions</u></a> and <a class="clickable footer-link" v-on:click="goPrivacy()" target="_blank"><u>Privacy Policy</u></a>.<br/>
        Powered by <i>Meeting HUB</i>
        </div>
    <b-modal centered v-model="showLeadership" >
      <div slot="modal-header">
      </div>
          <div class="center-text">
            <div><b>{{$t('login.leadership_text2')}}</b><br/>{{$t('login.leadership_text3')}}</div>
            <br/>
            <div>{{$t('login.leadership_text4')}}</div><br/>
            <div v-for="leader in leadership" :key="leader.id">
              <b-button class="login-leader-button"  size="sm" @click.stop="loginAsLeader(leader)" variant="light-blue-outline">
              {{leader.name}} - <i>{{leader.role_name}}</i>
            </b-button>
            </div>
            <br/>
            <div>{{$t('login.leadership_text5')}}</div>
            <div>{{$t('login.leadership_text6')}}</div>
            <br/>
            <b-button  v-on:click="goToDash" variant="link">
              <b>{{$t('login.leadership_text7')}}</b>
            </b-button>
        </div>
        <div slot="modal-footer">
        </div>
      </b-modal>
      <b-modal id="modal-center" class="login-modal" v-model="showPassReset" centered :title="$t('login.forgot_password')">
          <div>{{$t('login.forgot_text_1')}}</div>
          <div>{{$t('login.forgot_text_2')}}</div>
          <div id="reset-name-wrapper" class="fit-width">
            <span class="form-box-name">{{$t('login.account_name')}}</span><b-form-input v-model="reset_name" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" class="form-box-input"></b-form-input>
          </div>
          <div v-if="showResetAlert" class="center-text">
            <b-alert variant="danger" dismissible :show="showResetAlert" @dismissed="showResetAlert=false" class="center-text">
                     {{reset_alert}}
            </b-alert>
          </div>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showPassReset=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="resetPass" variant="clear-blue"><div class="email-button"></div><span>{{$t('global.button_send')}}</span></b-button>
          </div>
        </b-modal>
      <b-modal centered v-model="showSelectedLeader" >
      <div slot="modal-header">
      </div>
          <div v-if="currentLeader && !currentLeader.has_pass" class="center-text">
              <div v-html="$t('login.leadership_email_sent')"></div>
              <br/>
              <b-button  v-on:click="showSelectedLeader=false;" variant="link">
              {{$t('global.button_ok')}}
            </b-button>
          </div>
          <div v-if="currentLeader && currentLeader.has_pass" class="center-text">
            <div>{{$t('login.leadership_exists')}}</div>
            <br/>
            <b-button  v-on:click="username=currentLeader.email; password=''; showSelectedLeader=false;" variant="link">
              {{$t('login.leadership_login')}}
            </b-button>
            <br/>
            <div>{{$t('login.leadership_reset')}}</div>
          </div>
        <div slot="modal-footer">
        </div>
      </b-modal>
      <b-modal centered v-model="showHasPass" >
      <div slot="modal-header">
      </div>
          <div>
            {{$t('login.has_pass')}}
          </div>
        <div slot="modal-footer" class="submit-box-2">
              <b-button class="action-button" v-on:click="username=requestEmail;showHasPass=false;" variant="clear-blue"><div class="login-button"></div><span>{{$t('global.button_login')}}</span></b-button>
              <b-button class="action-button" v-on:click="sendEmail" variant="clear-blue"><div class="email-button"></div><span>{{$t('login.send_email')}}</span></b-button>
            </div>
      </b-modal>
  </div>
</template>
<style scoped lang="scss">
#welcome-text{
  margin:30px auto;
  padding: 0 10px;
  margin-top: 20px;
  max-width: 600px;
  text-align: center;
}
#reset-name-wrapper{
  display: grid; margin: 10px auto;
}
.login-leader-button{
  margin: 5px;border-radius: 20px;width: 320px; text-align:center;
}
#login-alert{
  text-align:center;grid-column:1/3;margin:auto;
}
#sub-text{
  color: #ccc;
}
#password-field{
  grid-column: 1/2;
}
#invis-password{
  grid-column: 1/2;opacity:0;
}
.login-step-box{
  position: absolute;width: 100%;
}
#forgot-password{
  text-align: center;width: 100%;    margin: 20px auto auto auto;
}
.next-icon{
  width: 35px !important;height: 35px !important;
}
#first-next-button{
  grid-row:1/2;grid-column:2/3
}
.login-next-button{
  margin: auto 10px;padding: 0px;;
}
.login-form-box{
  display:grid;grid-template-columns: auto max-content;margin:auto;
}
#permission-text{
  height: 50px;line-height: 40px;border-bottom: 1px solid #aaa;
}
.log-title{
      background-color: #C8C9C7;
    padding: 5px;
    font-size: 18px;
     color: black;
}
#cc-box{
  margin: auto;margin-bottom:25px; 
    background-image: url("../assets/CClogo-horizontal-white.png");
    background-repeat: no-repeat;
    background-size: auto 130px;
    background-position: center;
    width:100%;
    background-color: #424143;
    height: 150px;
}
#logo-box-wrapper{
  margin: auto; max-width: 600px;
}
#logo-box{
    margin: 0 10px;
    background-image: url("../assets/"+$VUE_APP_LOGIN_LOGO+".png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-color: #FFFFFF;
    height: 150px;
}
#language-flags{
  display:grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;margin:auto;
}
.language-flag{
  margin: auto 10px;padding:0px;
}
.login-field{
  max-width:400px; margin:10px auto;background-color:#f5f7f7;
}
#email-reset{
  max-width:400px; margin:10px auto;background-color:#f5f7f7;
}
#login-footer{
  position: relative;
}
@media (max-width: 755px) {
  #login-footer{
  font-size: 12px;
}
}
  .login-modal .modal-dialog {
    max-width: 700px;
    width: 700px;
  }
  .centre-text {
    text-align: center;
    display: block;
    font-weight: normal;
  }
  #centre-div-parent {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: grid;
    grid-template-rows: minmax(50px,auto) auto auto max-content;
    text-align: center;
    max-width:100%;
  }

  #terms-modal .modal-dialog {
    width: 80%;
    max-width:80%;
  }
  .submit-button-login {
    width: 162px;
    height: 46px;
    margin: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 0px 15px;
  }
  
  #choiceDiv {
    text-align: center;
  }
  .login-form-wrapper{
    max-width: 600px;
    margin: auto;
  }
  .login-form {
    margin: 0 10px;
    margin-bottom: 30px;height: 300px;position:relative; overflow:hidden;
    border: solid 1px #aaa;
    
    text-align: right;
  }
.login-form h1 {
	text-align: center;
	color: #4d4d4d;
	font-size: 24px;
	padding: 20px 0 20px 0;
}
.login-form input[type="password"],
.login-form input[type="text"] {
	width: 100%;
	padding: 15px;
	border: 1px solid #dddddd;
	margin-bottom: 15px;
	box-sizing:border-box;
}
.login-form input[type="submit"] {
	width: 100%;
	padding: 15px;
	background-color: #535b63;
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	font-weight: bold;
	color: #ffffff;
}
  #loginText, #permission {
    width: 100%;
    display: block;
  }
#forgotPass{
	font-size: 12px;
	color: #B2CEF0;
	display: block;
	text-align:left;
}
.righttolefttrans-enter-active, .righttolefttrans-leave-active {
    transition: all 0.5s ease-in;
  }
  .righttolefttrans-enter {
    transform: translateX(100%);
  }
  .righttolefttrans-leave-to {
    transform: translateX(-100%);
  }
</style>
<script>  
  import router from "../router"    
      
  export default {    
    name: "Login",
     watch: {
      '$i18n.locale': {
        handler: function () {
          document.title = this.$t('global.document_title');
        },
        deep: true
      },
    },
    data() {
      return {
        slide_transition : "righttolefttrans",
        showHasPass: false,
        showSelectedLeader: false,
        showLeadership: false,
        showResetSuccess: false,
        languageOptions: [],
        showRequestSuccess: false,
        showRequestAlert: false,
        alertRequestText: "",
        cantAccept: true,
        showTerms: false,
        showPassReset: false,
        reset_name: "",
        showAlert: false,
        alertText: this.$t('login.alert_text'),
        showResetAlert: false,
        alertResetText: "",
        password: "",
        username: "",
        pages: [{ url: "/admin/dashboard", name: this.$t('login.login_option1') }, { url: "/controller/view", name: this.$t('login.login_option2')}],
        redirect: "/admin/dashboard",
        browser_good: true,
        requestEmail: "",
        reset_alert: "",
        leadership: [],
        currentLeader: null,
        loginStep: 0
      }
    },
    methods: {
      submitEmail(){
        this.showAlert = false;
        this.$http.post("/api/member/logininfo", { email: this.username }).then((response) => {
          if (response.data.success === true) {
            if(response.data.has_pass==1){
              this.loginStep = 1;
              if(this.password.length>0){
                this.login();
              }
              setTimeout(() => {
                document.getElementById("password-field").focus();
              }, 500);
              
            }else if(response.data.access && !response.data.has_pass){
              this.loginStep = 4;
              this.reset_name = this.username;
              this.resetPass();
            }else if(response.data.some_access){
              this.loginStep = 2;
              this.sendEmail(this.username);
            }else{
              this.loginStep = 3;
              this.sendEmail(this.username);
            }
          }else{
            
            this.alertText = this.$t('login.alert_no_account');
            this.showAlert = true;
          }
        }).catch((e) => { 
          console.log(e);
          this.alertText = this.$t('login.alert_no_account');
            this.showAlert = true;});
      },
      loginAsLeader(leader){
        this.currentLeader = leader;
        if(this.currentLeader.has_pass){
          this.showSelectedLeader = true;
          this.showLeadership = false;
        }else{
          this.$http.post("/api/password/reset", {name: this.currentLeader.email}).then((response) => {
          if (response.data.sent === true) {
           this.showSelectedLeader = true;
           this.showLeadership = false;
          }
        }).catch(() => {
        });
        }
        
      },
       getLanguages(){
        this.$http.post("/api/organization/languages/all", {}).then((response) => {
          if (response.data.success === true) {
            this.languageOptions = response.data.languages;
            var userLang = navigator.language || navigator.userLanguage;
            /*const lang = userLang? userLang.split("-")[0] : this.getCookie("language", "en"); 
            for(let language of this.languageOptions){
              if(language.abbrev == lang){
                this.loadLanguageAsync(lang, false);
                return;
              }
            }*/
            this.setLanguageIfAvailable();
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      goToSignup(){
        router.push("/setup");
      },
      getLeadership(){
        this.$http.post("/api/organization/leadership", {}).then((response) => {
          if (response.data.success === true) {
            this.leadership = response.data.members;
            if(this.leandership && this.leadership.length<3){
              this.goToDash();
            }else{
              this.showLeadership = true;
            }
          }
        }).catch((errors) => {
          console.log(errors)
        });
      },
      acceptedTerms() {
        this.showAlert = false;
        let data = {
          username: this.username,
          password: this.password,
          admin: this.redirect === "/admin/dashboard"
        }
        this.$http.post("/api/terms", data).then((response) => {
          if (response.data.success === true) {
            if (response.data.terms === true) {
              this.auth();
            } else {
              this.showTerms = true;
            }
          } else {
            this.alertText = this.$t('login.alert_text');
            this.showAlert = true;
          }
        }).catch(() => {
          this.alertText = this.$t('login.alert_text2');
          this.showAlert = true;
        });
      },
      changedRedirect() {
      },
      login() {
        if(this.password==""){
          return;
        }
        this.showAlert = false;
        let data = {    
          username: this.username,    
          password: this.password,
          admin: this.redirect === "/admin/dashboard"
        }    
        this.$http.post("/api/login", data).then((response) => {
          if (response.data.success === true) {
            if (response.data.terms === true) {
              this.auth();
            } else {
              this.showTerms = true;
            }
          } else if(response.data.error){
            if(response.data.error.code == 1){
              this.alertText = this.$t('login.alert_text');this.showAlert = true;}
            else  if(response.data.error.code == 2){
              this.alertText = this.$t('login.alert_disabled');this.showAlert = true;}
            else if(response.data.error.code == 101){
              this.alertText = this.$t('login.alert_dev');}
            this.showAlert = true;
          }
        }).catch(() => {
          this.alertText = this.$t('login.alert_text2');
          this.showAlert = true;
        });
      },
      goToDash(){
        if(this.isMobile()){
              router.push("/mobile");
            }else {
              router.push("/admin/dashboard/");
            }
      },
      auth(){
        var self = this;
        this.loginAuthenticate(function(){
            self.permissions.previousSpeakers = false;
            self.permissions.previousDates = false;
            self.permissions.showDisabledSlides = true;
            self.permissions.filterRoleId = 0;
            self.permissions.sectionFilterId = 0;
            self.permissions.shownAnnouncements = false;
            self.permissions.populateVariables = !self.permissions.organization.is_parent;
            if(self.permissions.admin==1 && !self.permissions.organization.is_parent){
              self.getLeadership();
              return;
            }
            if(self.permissions.admin ==0 && self.permissions.access == 0){
              if(self.permissions.organization.parent_name == "" || self.permissions.organization.parent_name == null){
                router.push("/contactdash");
              }else{
                self.$http.post("/api/member/getmanagepermissions", {}).then((response) => {
                if (response.data.success && response.data.some_access) {
                  router.push("/userdash");
                }else{
                  router.push("/memberinfo/"+self.permissions.user.uid);
                }
              }).catch(() => {
                router.push("/memberinfo/"+self.permissions.user.uid);
              });
              }
              
            }else if(self.permissions.organization.id == 1){
              router.push("/admin/regions");
           // }else if(self.permissions.organization.needs_payment || self.permissions.organization.payment_due){
            //  router.push("/payment");
            //}else if(self.permissions.organization.onboard>=0 && !self.permissions.organization.is_parent){
            //  router.push("/onboard");
            }else if (self.permissions.organization.is_parent && self.redirect == "/admin/dashboard") {
              router.push("/admin/regiondash");
            } else if(self.isMobile()){
              router.push("/mobile");
            }else {
              router.push(self.redirect);
            }
        })
      },
      resetPass(e) {
        this.showResetAlert = false;
         this.showResetSuccess = false;
         if(e){
          e.preventDefault();
         }
        this.$http.post("/api/password/reset", {name: this.reset_name}).then((response) => {
          if (response.data.sent === true) {
            this.showResetAlert = false;
            this.showPassReset = false;
            this.showResetSuccess = true;
          } else {
            this.reset_alert = response.data.errorcode>0? this.$t('login.reset_alert'+response.data.errorcode): this.$t('login.reset_error');
            this.showResetAlert = true;
          }
        }).catch(() => {
          this.reset_alert = this.$t('login.reset_error');
          this.showResetAlert = true;
        });
      },
      checkPassThenSend(){
        this.$http.post("/api/member/haspass", { email: this.requestEmail }).then((response) => {
          if (response.data.success === true) {
            if(response.data.has_pass==1){
              this.showHasPass = true;
            }else{
              this.sendEmail(this.requestEmail);
            }
          } 
        }).catch(() => { });
      },
      sendEmail(username) {
        //this.showHasPass=false;
        //this.showRequestAlert = false;
        //this.showRequestSuccess = false;
        this.$http.post("/api/member/photo/request/email/byemail", { email: username }).then((response) => {
          if (!response.data.success){
            this.showAlert = true;
            this.alertText = response.data.error;
          }
        }).catch(() => { });
      },
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          this.cantAccept = false;
        }
      },
      isMobile() {
        /*var check = false;
        (function(a){if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(a.substr(0,4)))check=true})(navigator.userAgent||navigator.vendor||window.opera);
        return check;*/
        let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

        // Screen resolution method
        if (!isMobile) {
            let screenWidth = window.screen.width;
            let screenHeight = window.screen.height;
            isMobile = (screenWidth < 768 || screenHeight < 768);
        }

        // CSS media queries method
        if (!isMobile) {
            let bodyElement = document.getElementsByTagName('body')[0];
            isMobile = window.getComputedStyle(bodyElement).getPropertyValue('content').indexOf('mobile') !== -1;
        }

        return isMobile;
      },
       goTerms(){
          if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/terms-of-service", "_blank");
          }else{
            window.open("https://bnitos.com", "_blank");
          }
       },
       goPrivacy(){
         if(this.isCorporateConnections){
           window.open("https://www.corporateconnections.com/privacy-policy", "_blank");
         }else{
           window.open("https://bnitos.com/privacy", "_blank");
         }
       }
    },
    created(){
      this.getLanguages();
    },
    mounted() {
      this.browser_good = this.detectIE() < 0;
      document.title = this.$t('global.document_title');
    }
  }
</script>
