<template>
  <div>
    <Header :useruid="this.$route.params.useruid" :title="$t('performance.manage_performance')" :showDate="true"></Header>
    <div id="performance-page-wrapper" class="rel-grid-container">
      <div ></div>
      <div>
        <Tip :tipNumber="15"></Tip>
        <div v-if="this.$route.params.useruid == undefined  && tracking_periods!=0"  id="attendance-buttons">
          <b-button v-show="!this.$route.params.org" id="previous-button"  v-on:click="previousPerformance" class="action-button" variant="clear-blue">
            <div class="back-button"></div><span>{{$t('global.button_back')}}</span>
          </b-button>
          <div id="info-wrapper"><span id="organization-name">{{permissions.organization.name}}</span> > <b>{{current_date.length>0 ? $d(new Date(current_date.split("-").join("/") + " 00:01"), 'short') : ''}}</b></div>
          <b-button id="next-button" v-show="current_date != next_meeting && !this.$route.params.org" v-on:click="nextPerformance" class="action-button" variant="clear-blue">
            <div class="next-button"></div><span>{{$t('global.button_next')}}</span>
          </b-button> 
        </div>
        <div v-if="tracking_periods==0" id="choose-periods">{{$t('performance.choose_periods')}}</div>
        <form v-on:submit.prevent="updateReports" v-if="tracking_periods!=0">
            <div id="reporting-periods" class="skinned-text">{{$t('performance.reporting_periods')}}</div>
            <div id="reports-grid" class="fit-width">
              <div id="measurements-text" class="skinned-text">{{$t('performance.measurements_text')}}</div>
              <div class="report-column">
                <div id="empty-header" class="report-header"></div>
                <div class="report-header">{{split_referrals==1? $t('performance.internal_referrals') : $t('performance.referrals_text') }}</div>
                <div v-if="split_referrals==1" class="report-header">{{$t('performance.external_referrals')}}</div>
                <div class="report-header">{{$t('performance.visitors_text')}}</div>
                <div class="report-header">{{$t('performance.one_to_ones')}}</div>
                <div class="report-header">{{$t('performance.tyfcb_text')}}</div>
                <div class="report-header">{{$t('performance.ceus_text')}}</div>
              </div>
              <div v-for="r in reports.filter(v => (v.period_id & tracking_periods)>0)"  :key="r.period_id" class="report-column">
                <div class="report-header">{{$t('general.tracking_option_'+r.period_id)}}</div>
                <div><b-form-input v-model="r.referrals" type="number" class="medium-input" :state="r.referrals.length>0? null : false" /></div>
                <div v-if="split_referrals==1"><b-form-input  v-model="r.external_referrals" type="number" class="medium-input" :state="r.external_referrals.length>0? null : false" /></div>
                <div><b-form-input v-model="r.visitors" type="number" class="medium-input" :state="r.visitors.length>0? null : false" /></div>
                <div><b-form-input v-model="r.one_to_ones" type="number" class="medium-input" :state="r.one_to_ones.length>0? null : false" /></div>
                <div><b-form-input v-model="r.tyfcb" type="number" class="medium-input" :state="r.tyfcb.length>0? null : false" /></div>
                <div><b-form-input v-model="r.ceus" type="number" class="medium-input" :state="r.ceus.length>0? null : false" /></div>
              </div>
            </div>
          
          <br />
          <div v-if="showAlert" class="performance-alert-wrapper">
          <b-alert variant="warning" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{$t('performance.error_type1')}}
          </b-alert>
        </div>
          <div v-if="showSuccess" class="performance-alert-wrapper">
            <b-alert variant="success" dismissible :show="showSuccess" @dismissed="showSuccess=false">
              {{$t('performance.thanks_message_1')}}<br />
              {{$t('performance.thanks_message_2')}}
            </b-alert>
          </div>
          <div class="submit-box-2">
            <b-button class="action-button" v-on:click="clearValues" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_clear')}}</span></b-button>
            <b-button class="action-button" v-if="!this.$route.params.org" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
          </div>
        </form>
      </div>
      <div class="div-space"></div>
    </div>
    <Footer :useruid="this.$route.params.useruid"></Footer>
  </div>
</template>
<style scoped>
#performance-page-wrapper{
  max-width:100%;
}
#choose-periods{
  text-align:center; font-size:18px;
}
#previous-button{
  margin:auto;margin-left:0px;
}
#next-button{
  margin:auto; margin-right:0px;
}
#info-wrapper{
  grid-column:2/3; font-size: 24px; text-align:center;
}
#organization-name{
  color:red
}
#reporting-periods{
  color:#3cd2f0;font-size:15px;text-align: center;margin-bottom: 10px;
}
#reports-grid{
  display: grid;grid-template-columns: 20px repeat(8,max-content); ; grid-gap: 30px; margin:auto;
}
#measurements-text{
  color:#3cd2f0;font-size:15px;writing-mode: vertical-rl;text-orientation: upright;text-align: center;
}
#empty-header{
  background-color: #fff; border: none;
}
.report-column{
  display:grid; grid-template-columns: max-content;grid-gap: 10px;
}
.performance-alert-wrapper{
  max-width: 600px;margin: auto;
}
#attendance-buttons{
  display:grid;grid-template-columns: max-content auto max-content;
  width: 1000px;margin: 20px auto;
}
 .report-header{
   height: 38px;
   width:200px;
   line-height: 38px;
   font-size: 18px;
   text-align: center;
   background-color: #ccc;
   border: 1px solid #aaa;
 }
</style>
<script>
  import router from "../router"
    export default {
    name: "Reports",
      data() {
        return {
          showAlert: false,
          showAddReport : false,
          saveVariant: "blue",
          title: "",
          type: 0,
          reports: [],
          current_date: '',
          dayIndex: 0,
          dates: [],
          tracking_periods: 0,
          next_meeting : '',
          showSuccess: false,
          useruid: "",
          split_referrals: false
        }
      },
    methods: {
      previousPerformance(){
        var d = new Date("" + this.current_date.split("-").join("/") + " 00:01");
        d.setDate(d.getDate() - 7);
        this.current_date = d.toISOString().substr(0,10);
        this.getReports();

       /* if(this.dayIndex<this.dates.length-1){
          this.dayIndex+=1;
          this.current_date = this.dates[this.dayIndex];
          this.getReports();
        }*/
      },
      nextPerformance(){
        if(this.current_date != this.next_meeting){
          var d = new Date("" + this.current_date.split("-").join("/") + " 00:01");
          d.setDate(d.getDate() + 7);
          this.current_date = d.toISOString().substr(0,10);
          this.getReports();
        }
        

        /*if(this.dayIndex>0){
          this.dayIndex-=1;
          this.current_date = this.dates[this.dayIndex];
          this.getReports();
        }*/
      },
        clearReports(){
          var ids = [1,2,4,8,16,32,64];
          this.reports = [];
          for(var i =0; i<ids.length; i++){
            this.reports.push({id: null,period_id:ids[i], referrals: "0", visitors: "0", one_to_ones: "0", tyfcb: "0", ceus: "0", external_referrals: "0", init: true})
          }
        },
        clearValues(){
          for(var i=0; i<this.reports.length;i++){
            this.reports[i].referrals = "0";
            this.reports[i].external_referrals = "0";
            this.reports[i].visitors = "0";
            this.reports[i].one_to_ones = "0";
            this.reports[i].tyfcb = "0";
            this.reports[i].ceus = "0";
          }
        },
      getReports() {
        this.$http.post("/api/organization/reports/get"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid, day: this.current_date}).then((response) => {
          if (response.data.success) {
            this.clearReports();
            for(var i=0; i<response.data.reports.length; i++){
              for(var j=0; j<this.reports.length; j++){
                if(this.reports[j].period_id == response.data.reports[i].period_id){
                  response.data.reports[i].referrals +="";
                  response.data.reports[i].visitors +="";
                  response.data.reports[i].one_to_ones +="";
                  response.data.reports[i].tyfcb +="";
                  response.data.reports[i].ceus +="";
                  response.data.reports[i].external_referrals +="";
                  this.$set(this.reports,j,response.data.reports[i]);
                  break;
                }
              }
              
            }
          }
        }).catch(() => {
        });
      },
      check(){
        for(var i=0; i<this.reports.length; i++){
          if(this.reports[i].referrals.length==0 || this.reports[i].visitors.length==0 || this.reports[i].one_to_ones.length==0 || this.reports[i].tyfcb.length==0 || this.reports[i].ceus.length==0){
            return false;
          }
        }
        return true;
      },
      updateReports() {
        this.showAlert = false;
        if(!this.check()){
          this.showAlert = true;
          return;
        }
        this.$http.post("/api/organization/reports/update"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid,reports: this.reports, day: this.current_date}).then((response) => {
          if (response.data.success === true) {
            this.saveVariant = 'success';
             if(this.useruid==''){
              this.back();
            }else{
              this.getReports();
               this.showSuccess = true;
            }
           
          } else {
            this.saveVariant = 'danger';
          }
        }).catch((errors) => {
          console.log(errors);
          this.$bvToast.show('serverError');
        });
      },
      getReportDates(){
        this.$http.post("/api/organization/reports/info"+(this.useruid==''? "" : "/byuseruid"), {useruid: this.useruid}).then((response) => {
          if (response.data.success) {
              this.dates = response.data.dates;
              this.tracking_periods = response.data.tracking_periods;
              this.next_meeting = response.data.next_meeting;
              this.split_referrals = response.data.split_referrals;
              this.dayIndex = 0;
              this.current_date = this.dates[this.dayIndex];
              if(this.$route.params.day){
                  this.current_date = this.$route.params.day;
                }
              this.getReports();
          }
          }).catch(() => {
        });
          
      },
      back() {
        router.push("/admin/dashboard");
      },
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-performance"), "active-menu");
      this.removeClass(document.getElementById("menu-chapter"), "active-menu");
    },
    mounted() {
      if(this.$route.params.useruid){
        this.useruid = this.$route.params.useruid;
      }
      this.addClass(document.getElementById("menu-performance"), "active-menu");
      this.addClass(document.getElementById("menu-chapter"), "active-menu");
      this.getReportDates();
    }
  }
</script>
